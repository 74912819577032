import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  background: #f5f5f5 !important;
`;

export const Lists = styled(List)`
  backgroundcolor: #305496;
  overflow: hidden;
  height: 100%;
  overflowy: scroll;
  marginbottom: 2.5rem;
  padding: 4px !important;
`;

export const ListItems = styled(ListItem)`
  display: block;
  height: 46px;
  margin-bottom: 5px;
  overflow: hidden;
  width: ${(props) => (props?.open ? "160px !important" : "")};
  background-color: ${(props) => (props?.active ? "white !important" : "")};
  border-radius: ${(props) => (props?.active ? "6px !important" : "")};
  margin-left: ${(props) => (props?.active ? "2px !important" : "")};
  .MuiListItemButton-gutters {
    height: 20px;
  }
`;

export const ResetListItems = styled(ListItem)`
  padding: 0px !important;
  position: initial !important;
  display: inherit !important;
`;

export const ListItemButtons = styled(ListItemButton)`
  min-height: 48px;
`;
export const ListItemIcons = styled(ListItemIcon)`
  min-width: 0px !important;
  justify-content: center;
  color: white;
  height: 25px;
`;

export const ListItemTexts = styled(ListItemText)`
  font-style: normal !important;
  font-weight: 400px !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: ${(props) =>
    props?.active ? "#305496 !important" : "#E2E0E0 !important"};
`;
export const MainBox = styled(Box)`
  background: #f5f5f5 !important;
  // border-radius: 6px 0 0 0 !important;
  padding: 1rem 1.5rem !important;
  height: 100vh !important;
  flex-grow: 1;
  /*width: calc(100% - 3rem) !important; */
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
